import { action, payload } from 'ts-action'
import { IProperty } from 'models/Property'
import { IError, IPayload } from 'models/Global'

export const GetProperty = action('GetProperty', payload<IPayload<IProperty>>())

export const PropertyErrors = action('PropertyErrors', payload<IError>())

export const PropertyFetching = action('PropertyFetching', payload<boolean>())

export const ClearProperty = action('ClearProperty')
